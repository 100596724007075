import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../../settings";

/**
 * Fetches current market depth data for a given crypto
 * @param {string} cryptoGuid
 * @returns {Promise<MarketDepthData[]>}
 */
const fetchMarketDepth = (cryptoGuid) =>
  axios
    .get(`${API_URL}/cryptos/depth/current/${cryptoGuid}`)
    .then((response) => response.data);

/**
 * Fetch current market depth for given crypto from cache or fetch from net.
 * @param cryptoGuid
 * @returns {import('react-query').UseQueryResult<MarketDepthData[], unknown>}
 */
export function useCurrentMarketDepth(cryptoGuid) {
  return useQuery(
    ["marketDepth", "current", cryptoGuid],
    () => fetchMarketDepth(cryptoGuid),
    { enabled: cryptoGuid !== undefined },
  );
}

/**
 * Fetches market depth history for a given crypto
 * @param {string} cryptoGuid uuid for crypto
 * @returns {Promise<MarketDepthHistoryList>}
 */
const fetchMarketDepthHistory = (cryptoGuid, startDate)=>
    axios
      .get(`${API_URL}/cryptos/depth/history/${cryptoGuid}`, {params: {start: startDate}})
      .then((response)=>response.data);

/**
 * @param {string} cryptoGuid uuid for crypto
 * @returns {import('react-query').UseQueryResult<MarketDepthHistoryList, unknown>}
 */
export function useMarketDepthHistory(cryptoGuid, startDate) {
  return useQuery(
      ["marketDepthHistory", cryptoGuid, startDate],
      ()=>fetchMarketDepthHistory(cryptoGuid, startDate),
      {enabled: cryptoGuid != null});
}